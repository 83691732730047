import React from 'react'

const ExternLink = ({ href, children, title, className, ...restProps }) => (
	<a
		className={className}
		href={href}
		rel="noopener noreferrer"
		target="_blank"
		title={title || ``}
		{...restProps}
	>
		{children}
	</a>
)

export default ExternLink
