import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ExternLink from '../components/externLink'

const UsesPage = () => {
	return (
		<Layout>
			<SEO
				description="List of things that I use to get things done."
				keywords={[`anshumanv`, `gatsby`, `uses`, `usespage`]}
				title="Uses"
			/>

			<section className="flex flex-col h-full">
				<h1 className="text-3xl font-bold uppercase tracking-wider leading-loose">
					Uses 👑
				</h1>
				<span>
					Here's a list of Hardware, Tools and Configurations that I use to get
					things done. Totally inspired from{` `}
					<a className="link" href="https://wesbos.com/uses/">
						Wes Bos.
					</a>
				</span>
				<h3 className="text-xl leading-loose mt-5">💻 Hardware</h3>
				<ul className="list-disc">
					<li>Acer Predator Helios 300 (Mostly games or dabble in linux or windows ecosystem)</li>
					<li>2019 MacBook Pro</li>
					<li className="line-through">Fujitsu Lifebook A Series (Linux Machine)</li>
					<li className="line-through">2015 MacBook Air</li>
					<li className="line-through">HP 500 Netbook</li>
				</ul>

				<h3 className="text-xl leading-loose mt-5">👨‍💻 Editor + Terminal</h3>
				<ul className="list-disc">
					<li>
						VSCode as primary editor -{` `}
						<ExternLink
							className="link"
							href="https://howivscode.com/anshumanv"
							title="Anshuman's vscode extensions"
						>
							Extensions
						</ExternLink>
					</li>
					<li>
						iTerm2 with zsh as default terminal (detailed config/mods soon!)
					</li>
				</ul>

				<h3 className="text-xl leading-loose mt-5">🍎 Desktop Apps</h3>
				<ul className="list-disc">
					<li>Rocket to insert emoji(s).</li>
					<li>Spectacle to manage windows.</li>
					<li>RescueTime to track my activity.</li>
					<li>Franz as a single app for all communication services.</li>
					<li>(More Soon!)</li>
				</ul>

				<h3 className="text-xl leading-loose mt-5">🖱️ Other(s)</h3>
				<ul className="list-disc">
					<li>
						I use a wired RedGear gaming keyboard and mouse. (rare times when I
						actually play CSGO)
					</li>
					<li>Aircase sleeve to contain/transport my Mac.</li>
					<li>
						A simple Type-C to USB converter that I got for 100¥ from a daiso(no
						hub yet!).
					</li>
					<li>
						Transparent 13' hard case to protect my Mac.(I live in India, does
						make it a little heavy)
					</li>
					<li>WD My Passport 2TB External HDD</li>
					<li>
						Sennheiser CX 275 S Earphones (Quite effective noise cancellation)
					</li>
				</ul>
			</section>
		</Layout>
	)
}

export default UsesPage
